<template>
  <div id="div-follow">
    <h3>
      {{ t('title.Appointment') }}
    </h3>
    <b-card
      v-if="followUps === []"
      no-body
      class="p-4"
    >
      <b-card-body>
        <b-card-text class="text-center text-primary">
          {{ t('title.No Appointment') }}
        </b-card-text>
      </b-card-body>
    </b-card>
    <div
      v-else
      v-for="(data, index) in followUps"
      :key="index"
      class="card border-secondary"
    >
      <div class="card-body pb-0">
        <b-row>
          <b-col cols="4">
            <b-card
              bg-variant="light"
              text-variant="primary"
            >
              <b-card-text class="text-center">
                <h1 class="text-primary">
                  <strong>{{ data.date.substr(8, 2) }}</strong>
                </h1>
                <h6 class="text-primary">
                  {{ $helpers.getMonthName(data.date.substr(5, 2), $i18n.locale) }}
                </h6>
                <h6 class="text-primary">
                  {{ $helpers.getYearName(data.date.substr(0, 4), $i18n.locale) }}
                </h6>
              </b-card-text>
            </b-card>
          </b-col>
          <b-col
            cols="8"
            class="mt-1"
          >
            <h4>{{ data.time }} - {{ data.timE1 }}</h4>
            <small>{{ data.clinicName }}</small><br>
            <small>{{ data.doctorName }} </small><br>
            <small>{{ data.comeForText }}</small><br>
            <small>{{ data.appNote }}</small>
          </b-col>
        </b-row>
      </div>
      <!-- <div
        class="card-footer text-light bg-primary pt-1 pb-1"
        @click="showAppointmentInfo(data)"
      >
        {{ t('Button Detail') }}
      </div> -->
    </div>
    <b-modal
      id="appointmentModal"
      ref="appointmentModal"
      :hide-footer="true"
      centered
      size="lg"
      title=""
    >
      <b-card-text>
        <profile-appointment-card
          :appointment-info="appointmentInfo"
          @exit="onCloseModal"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
// import ProfileAppointmentCard from './ProfileAppointmentCard.vue'

export default {
  components: {
    // ProfileAppointmentCard,
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      hn: '',
      followUps: [],
      patientData: JSON.parse(localStorage.getItem('patientData')),
      appointmentInfo: '',
    }
  },
  created() {
    this.loadAppointmentList()
  },
  methods: {
    changeRoute() {
      this.$router.push({ name: 'member-followup' })
    },
    loadAppointmentList() {
      console.log(this.patientData)
      this.followUps = []
      this.$http({
        url: `http://api159.sriphat.com/api/Appointment/listOldAppointment/${this.patientData.hn}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        this.followUps = data
      }).catch(error => {
        console.log('loadFollowUpList', error)
      })
    },
    showAppointmentInfo(appointmentInfo) {
      this.appointmentInfo = appointmentInfo
      this.$refs.appointmentModal.show()
    },
    onCloseModal(id) {
      this.$bvModal.hide(id)
      this.loadAppointmentList()
    },
  },
}
</script>
